















































import moment from 'moment-timezone';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

/**
 * This component is used to display all the available items that are related to a patient
 * This can be used to display all the available test results for a patient or requisitions to be confirmed
 * it only displays the available items and emits an event when the user clicks on the view button
 */
@Component
export default class PatientsVirtualScroller extends Vue {
  @Prop({ type: Array }) public items: any[];
  @Prop({ type: String, default: null }) public activeItem: string;
  @Prop({ type: String, default: 'View' }) public buttonLabel: string;
  public search = '';
  public filteredItems: any[] = [];
  public sortedItems: any[] = [];
  public headers = [
    {
      text: 'First Name',
      value: 'patient.fname',
      align: 'left',
    },
    {
      text: 'Last Name',
      value: 'patient.lname',
      align: 'left',
    },
    {
      text: 'ID',
      value: 'patient.patientIdentifier',
      align: 'left',
    },
    {
      text: 'Days since last visit',
      value: 'daysSinceLastVisit',
      sortable: false,
      align: 'center',
    },
    {
      text: 'Critical',
      value: 'criticalReviewed',
      sortable: true,
      align: 'center',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
    },
  ];

  @Watch('filteredItems')
  public onFilteredItemsChanged() {
    const temp = [...this.filteredItems]; // copy the filtered items
    /*
     If any of the visits contain a critical result that is not reviewed, then
     it will have priority over the other visits. If both a and b requisitions have
     visits, then the one with less level will have priority (p0 is more important than p1).
     Otherwise, the one with the most recent visit date will have priority.
    */
    temp.sort((a, b) => {

      const requisitionALevel = this.getCriticalResultsReview(a.visits);
      const requisitionBLevel = this.getCriticalResultsReview(b.visits);
      /* Critical check section */
      if (!!requisitionALevel || !!requisitionBLevel) {
        if (requisitionALevel && requisitionBLevel) {
          if (parseInt(requisitionALevel.level, 10) < parseInt(requisitionBLevel.level, 10)) {
            return -1;
          }
          if (parseInt(requisitionALevel.level, 10) > parseInt(requisitionBLevel.level, 10)) {
            return 1;
          }
          return 0;
        } else if (requisitionALevel) {
          return -1;
        } else {
          return 1;
        }
      }

      const lastVisitA = this.calculateNextVisit(a.visits);
      const lastVisitB = this.calculateNextVisit(b.visits);
      if (lastVisitA === 'N/A') {
        return 1;
      }
      if (lastVisitB === 'N/A') {
        return -1;
      }

      if (lastVisitA.visitDate === lastVisitB.visitDate) {
        return 0;
      }
      return new Date(lastVisitA.visitDate).getTime() - new Date(lastVisitB.visitDate).getTime();
    });

    // add the visits with criticals to the top of the sorted items
    this.sortedItems = temp;
  }

  /**
   * @returns the initials of the patient
   * @param patient the patient object
   */
  public getPatientInitials(patient: any) {
    return (patient?.fname?.charAt(0) ?? '') + (patient?.lname?.charAt(0) ?? '');
  }

  /**
   * @returns the full patient name
   * @param patient the patient object
   */
  public getPatientFullName(patient: any) {
    return `${patient?.fname} ${patient?.lname} ${
      patient?.patientIdentifier ? ` - ${patient?.patientIdentifier}` : ''
    }`.trim();
  }

  /**
   * Emits an event when the user clicks on the view button
   * @param item the item that was clicked
   */
  public viewButtonClicked(item: any) {
    this.$emit('view-clicked', item.id);
  }

  public calculateNextVisit(visits: any[]) {
    if (!visits || visits.length === 0) {
      return 'N/A';
    }
    const visitCopy = [...visits];
    // sort th visits by the visit date
    visitCopy.sort((a, b) => {
      return new Date(a.visitDate).getTime() - new Date(b.visitDate).getTime();
    });
    // get the last visit
    const lastVisit = visitCopy[visitCopy.length - 1];
    return lastVisit;
  }

  public getDaysSinceLastVisit(visits: any[]) {
    const nextVisit = this.calculateNextVisit(visits);
    if (nextVisit === 'N/A') {
      return nextVisit;
    }
        // get the last visit date
    const lastVisitDate = moment(nextVisit.visitDate);
    // get the current date
    const currentDate = moment();
    // get the difference between the two dates
    return currentDate.diff(lastVisitDate, 'days');
  }

  /**
   * Get the result critical that is not reviewed from the visits.
   * If both visits have a critical not reviewed, get the ones that
   * has more priority.
   */
  public getCriticalResultsReview(visits: any[]) {
    let temp: any = null;
    if (!visits || visits.length === 0) {
      return false;
    }

    for (const visit of visits) {
      if (visit.criticalResult && !visit.criticalResult.criticalReviewed) {
        if (!temp) {
          temp = visit.criticalResult;
        } else {
          if (parseInt(visit.criticalResult.level, 10) > parseInt(temp.level, 10)) {
            temp = visit.criticalResult;
          }
        }
      }
    }
    return temp ? temp : false;
  }

  /**
   * Emits an event when the user clicks on the patient name
   */
  public patientButtonClicked() {
    this.$emit('patient-clicked');
  }

  @Watch('search')
  public onSearchChanged() {
    if (!this.search) {
      this.filteredItems = this.items;
      return;
    }
    this.filteredItems = this.items.filter((item) => {
      const patient = item.patient;
      const patientName = `${patient?.fname} ${patient?.lname} ${patient?.patientIdentifier}`.trim();
      return patientName.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  @Watch('items', { immediate: true })
  public onItemsChanged() {
    this.filteredItems = this.items;
  }
}
