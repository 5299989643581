


































































import { Component, Watch } from 'vue-property-decorator';
import {
  readHasPhysicianAccess,
  readHasNurseAccess,
  readHasReviewerAcccess,
} from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import TestResultItem from '@/components/TestResultItem.vue';
import PatientsVirtualScroller from '@/components/PatientsVirtualScroller.vue';
import ConfirmTestResultApproval from '@/components/modals/ConfirmTestResultApproval.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {
  dispatchGetRequisitionResults,
} from '@/store/crud/actions';
import { IUserProfile } from '@/interfaces/users';
import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import { IRequisition } from '@/interfaces/requisitions';
import { IVisit } from '@/interfaces/visits';

@Component({
  components: {
    TestResultItem,
    ConfirmTestResultApproval,
    PatientsVirtualScroller,
  },
})
export default class TestResult extends AppComponent {
  public fetchingAvailableRequisitions = false;
  public fetchingUserData = false;
  public requisitionsToReview: IRequisition[] = [];
  public selectedRequisitionId: string | null = null;
  public userProfile: IUserProfile | null = null;
  public digestedRequisitions: IRequisition[] = [];
  public isPhysician: boolean | null = true;
  public isReviewer: boolean | null = true;
  public isNurse: boolean | null = true;


  /**
   * created hook,
   * it calls async fetch data
   */
  public mounted() {
    this.onRouteChanged();
  }

  @Watch('$route')
  public async onRouteChanged() {
    if (this.$route.name === 'main-physician-results') {
      this.fetchData();
    }
    const selectedRequisitionId = this.$route.params?.requisitionId;
    if (selectedRequisitionId) {
      this.selectedRequisitionId = selectedRequisitionId;
    }
  }

  public get isFetchingGlobalData() {
    return this.fetchingAvailableRequisitions || this.fetchingUserData;
  }

  /**
   * Fetches necessary data for the page view
   */
  public async fetchData() {
    this.fetchingUserData = true; // used to show the loading spinner
    await this.$nextTick();

    this.userProfile = await readUserProfile(this.$store);
    this.isPhysician = await readHasPhysicianAccess(this.$store);
    this.isReviewer = await readHasReviewerAcccess(this.$store);
    this.isNurse = await readHasNurseAccess(this.$store);

    this.fetchingUserData = false;
    if ((this.isPhysician || this.isNurse) && this.userProfile && this.userProfile.id) {
      this.fetchingAvailableRequisitions = true;
      await this.$nextTick();
      this.requisitionsToReview = await dispatchGetRequisitionResults(this.$store);
      this.fetchingAvailableRequisitions = false;
    } else {
      // user is not available or not phisician
      this.$router.back();
    }
  }

  @Watch('requisitionsToReview')
  public async onResultsChanged() {
    this.digestedRequisitions = []; // reboot the array
    if (!this.requisitionsToReview || this.requisitionsToReview.length === 0) {
      return;
    }
    /**
     * in case of nurses we only show the requisitions that have critical values
     * critical value = critical Biomarker + result is out of the critical ranges defined by priority1 and priority2
     * for that we will need data from the biomarker and from the test
     */
    if (this.isNurse) {
      /*
        This will be a triple loop, First it loops over the requisitions to get all the visits
        then it loops over the visits to get all the biomarkers results
        then it loops over the biomarkers to see if they have critical values
        it does not need to complete the loop, if it finds a critical value then it is
        added to the requisition to the array
      */
      for (const requisition of this.requisitionsToReview as IRequisition[]) {
        // get the current patient biological sex
        const patientBiologicalSex = this.getPatientBiologicalSexFromRequisition(requisition);
        if (requisition.visits && requisition.visits.length > 0) {
          for (const visit of requisition.visits as IVisit[]) {
            let requisitionAdded = false;

            if (visit.biomarkerResults && visit.biomarkerResults.length > 0) {
              for (const biomarkerResult of visit.biomarkerResults as IBiomarkerResult[]) {
                if (
                  biomarkerResult.biomarker &&
                  Object.keys(biomarkerResult.biomarker).length > 0
                ) {
                  // check if biomarker is critical based on biomarker and patient sex
                  const isCritical = this.checkIfBiomarkerIsCritical(
                    biomarkerResult.biomarker,
                    patientBiologicalSex,
                  );
                  if (isCritical && biomarkerResult.testResultOutOfRange) {
                    /* Calculate if results are out of range */
                    const sexDetails = this.getBiomarkerSexDetailsForPatientBiologicalSex(
                      biomarkerResult.biomarker,
                      patientBiologicalSex,
                    );
                    const currentCritical = sexDetails?.critical;

                    if (currentCritical) {
                      const isCritical1 = currentCritical.isPriority1
                        ? this.calculateOutOfRangeResult(
                          currentCritical.priority1Range,
                          biomarkerResult.testResult)
                        : false;
                      const isCritical2 = currentCritical.isPriority2
                        ? this.calculateOutOfRangeResult(
                          currentCritical.priority2Range,
                          biomarkerResult.testResult)
                        : false;
                      /**
                       * Result is critical when biomarker is marked as critical
                       * and is priority 1 or 2, also it should be out of range
                       * according to the resunt and priority ranges
                       */
                      if (isCritical1 || isCritical2) {
                        if (!requisitionAdded) {
                          this.digestedRequisitions.push(requisition);
                          requisitionAdded = true;
                          break;
                        }
                      }
                    }
                  }
                }
              }
              if (requisitionAdded) {
                break;
              }
            }
          }
        }
      }
    } else {
      /* Physician case */
      this.digestedRequisitions = [...this.requisitionsToReview]; // clone to avoid reactivity with the original array
    }
  }

  public goToResultsStep(requisitionId: string) {
    this.$router.push({
      name: 'physician-results-step',
      params: {
        requisitionId,
      },
    });
  }
}
